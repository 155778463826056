import React from 'react';
import PageHeadingImage from './PageHeadingImage';
import moment from 'moment';

const BlogPageHeading = ({ title, author, date, image }) => (
  <React.Fragment>
    <div className="jumbotron">
      <h1 className="h1-responsive">{title}</h1>
      <h6>
        Written by {author}, {moment(date).format('Do MMMM YYYY')}
      </h6>
    </div>
    <PageHeadingImage image={image} />
  </React.Fragment>
);

export default BlogPageHeading;
