import React from 'react';

const PageSection = ({ children }) => (
  <section className="section mb-0 section-blog-fw">
    <div className="row">
      <div className="col-md-10 offset-md-1">{children}</div>
    </div>
  </section>
);

export default PageSection;
