import React from 'react';
import { Layout } from '../components/layout';
import { graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import PageSection from '../components/PageSection';
import PageContent from '../components/PageContent';
import BlogPageHeading from '../components/BlogPageHeading';

export default ({ data: { markdownRemark: page } }) => {
  const { title, description } = page.frontmatter;
  return (
    <Layout>
      <SEO title={title} description={description} />
      <PageSection>
        <BlogPageHeading {...page.frontmatter} />
        <PageContent content={page.html} />
        <div className="text-center mt-2">
          <Link to="blog">Back to blog posts</Link>
        </div>
      </PageSection>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        author
        date
        image
      }
    }
  }
`;
