import React from 'react';

const PageHeadingImage = ({ image }) =>
  image ? (
    <div className="z-depth-2">
      <img src={`/img/${image}`} className="img-fluid" style={{ width: '100%' }} />
    </div>
  ) : null;

export default PageHeadingImage;
